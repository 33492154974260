import palette from "../theme/palette";
import { styled } from "@mui/system";
import { Link } from "gatsby";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import theme from "../theme";

export const SCarrerWrapper = styled(Box)({
  position: "relative",
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    height: "unset",
  },
});

export const CarrerWrapperBackgroundInner = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: -1,

  "& svg": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.down("xl")]: {
    top: "-65px",
  },

  [theme.breakpoints.down("lg")]: {
    top: "-45px",
  },

  [theme.breakpoints.down("md")]: {
    top: "-22px",
  },
});

export const SCarrerTopSectionWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const SCarrerTopSection = styled(Box)({
  maxWidth: 597,
  width: "100%",
  marginBottom: 170,

  "& h1.MuiTypography-root": {
    marginBottom: 26,
    marginTop: 128,
  },

  "& p.MuiTypography-root": {
    maxWidth: 574,
  },

  [theme.breakpoints.down("lg")]: {
    marginBottom: 0,
  },
});

export const SImgTopSection = styled(Box)({
  marginTop: 63,
  width: 364,
  height: 95,

  "@media (max-width: 1148px)": {
    display: "none",
  },
});

export const SCareerDescriptionContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 170,

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    paddingBottom: 0,
  },
});

export const SCareerDescriptionContent = styled(Box)({
  maxWidth: 464,
  width: "100%",

  "& h2.MuiTypography-root": {
    marginBottom: 26,
  },

  "& p.MuiTypography-root ul li": {
    marginBottom: 3,
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "unset",

    "& p.MuiTypography-root": {
      marginBottom: 36,
    },
  },
});

export const SCareerDescriptionCollapse = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  "& svg": {
    transform: "rotate(180deg)",

    "@media (min-width: 900px)": {
      display: "none",
    },
  },

  "& .rotateSvg": {
    "& svg": {
      transform: "rotate(0deg)",
    },
  },
});

// JOBS SECTION
export const SJobsWrapper = styled(Box)({
  padding: "40px 20px",

  "@media (max-width: 899.99px)": {
    backgroundColor: palette.porcelain,
    margin: "-40px 0px",
  },

  "& h2.MuiTypography-root": {
    paddingBottom: 26,
    borderBottom: `1px solid ${palette.lightGray}`,
  },
});

export const SJobsContainer = styled(Box)({
  borderBottom: `1px solid ${palette.lightGray}`,
  padding: "60px 0px 26px 0px",

  "@media (max-width: 899.99px)": {
    paddingTop: 36,
  },
});

export const SJobContent = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  "@media (max-width: 899.99px)": {
    flexDirection: "column",
  },

  "&:last-child": {
    marginTop: 16,
  },

  "& .hashtagMobile": {
    userSelect: "none",
    "@media (max-width: 899.99px)": {
      backgroundColor: `${palette.amethystSmoke} !important`,
    },
  },

  "& .mb26Mobile": {
    "@media (max-width: 899.99px)": {
      marginBottom: 26,
    },
  },
});

export const STitle = styled(Box)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "26px",
  letterSpacing: "0.15px",
  color: palette.amethystSmoke,
  marginBottom: 7,
});

export const SJobNameWrapper = styled(Box)({
  maxWidth: 264,
  width: "100%",

  "@media (max-width: 899.99px)": {
    marginBottom: 26,
  },
});

export const SJobName = styled(Link)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "26px",
  letterSpacing: "0.15px",
  color: palette.purpleBlue,
  marginBottom: 16,
});

export const SSalary = styled(Box)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "26px",
  letterSpacing: "0.15px",
});

export const SCustomWidth = styled(Box)({
  maxWidth: 180,
  width: "100%",
});

export const SSubtitle = styled(Box)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "26px",
  letterSpacing: "0.15px",
});

export const SKeyTechnologiesContainer = styled(Box)({
  maxWidth: 380,
  width: "100%",

  "@media (max-width: 899.99px)": {
    maxWidth: "unset",
  },
});

export const SKeyTechnologiesContent = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
});

export const SKeyTechnologiesHashtag = styled(Box)({
  backgroundColor: palette.porcelain,
  padding: "10.5px 22px",
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "21px",
  marginRight: 8,
  marginTop: 8,
  cursor: "pointer",

  "&:last-child": {
    marginRight: 0,
  },
});

export const SBottomRightSection = styled(Box)({
  maxWidth: 380,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: 20,

  '&.list': {
    flexDirection: "column",
    justifyContent: "space-between",
  },

  "@media (max-width: 899.99px)": {
    width: "100%",
    marginTop: 26,
    marginLeft: 0,

    '&.list': {
      marginTop: 0,
    },
  },
});

export const SCareerDescriptionUl = styled(Box)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",
});
// END OF JOBS SECTION

// VACANCY PAGE
export const SVacancyWrapper = styled(Box)({
  margin: "279px 0px 170px 0px",

  "@media (max-width: 899.99px)": {
    margin: "74px 0px 0px 0px",
  },

  "& h2.MuiTypography-root": {
    maxWidth: 584,

    "@media (max-width: 899.99px)": {
      marginBottom: 20,
    },
  },
});

export const SVacancyContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",

  "@media (max-width: 899.99px)": {
    flexDirection: "column",
    alignItems: "unset",
  },
});

export const SVacancyContent = styled(Box)({
  display: "flex",
  width: "100%",

  "& > div": {
    "&:first-of-type": {
      marginRight: 100,
    },
  },

  "@media (max-width: 899.99px)": {
    flexWrap: "wrap",

    "& > div": {
      marginTop: 6,
      marginRight: 40,
    },
  },

  [theme.breakpoints.down("sm")]: {
    "& > div": {
      "&:first-of-type": {
        marginRight: 40,
      },
    },
  },
});

export const SBtnRespond = styled(Button)({
  "&.MuiButtonBase-root.MuiButton-root": {
    backgroundColor: palette.purpleBlue,
    color: palette.secondary,
    width: 264,
    height: 60,
    borderRadius: 8,
    marginTop: 32,
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
    border: `1px solid ${palette.purpleBlue} `,

    "&:hover": {
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,
    },

    "@media (max-width: 575px)": {
      width: "100%",
    },
  },
});

export const SDescriptionContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",

  "@media (max-width: 899.99px)": {
    flexDirection: "column-reverse",
  },

  "& > div:last-child": {
    border: "none",

    "@media (max-width: 899.99px)": {
      paddingBottom: 36,
    },
  },
});

export const SVacancySubtitle = styled(Box)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "26px",
  letterSpacing: "0.15px",
  marginBottom: 26,
});

export const SMainMargin = styled(Box)({
  paddingBottom: 61,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",

  "&:last-child": {
    borderBottom: `1px solid ${palette.lightGray}`,
    "@media (max-width: 899.99px)": {
      border: "none",
    },
  },

  "@media (max-width: 899.99px)": {
    paddingBottom: 36,

    "&:last-child": {
      paddingBottom: 0,
    },
  },

  "& ul li": {
    marginBottom: 5,
  },

  "& .MuiTypography-root": {
    maxWidth: 760,
  },
});

export const SWorkplaceFragment = styled(Box)({
  "& p": {
    marginBottom: 36,
  },
});
// END OF VACANCY PAGE
