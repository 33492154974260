import React, { useState } from "react";
import { graphql } from "gatsby";
import Typography from "@mui/material/Typography";
import Seo from "../components/seo";
import Layout from "../layout";
import {
  CarrerWrapperBackgroundInner,
  SBottomRightSection,
  SCareerDescriptionCollapse,
  SCareerDescriptionContainer,
  SCareerDescriptionContent,
  SCareerDescriptionUl,
  SCarrerTopSection,
  SCarrerTopSectionWrapper,
  SCarrerWrapper,
  SCustomWidth,
  SImgTopSection,
  SJobContent,
  SJobName,
  SJobNameWrapper,
  SJobsContainer,
  SJobsWrapper,
  SSubtitle,
  STitle,
} from "../styles/career";
import CollapseArrow from "../assets/icons/CollapseArrow";
import ListKeyTechnologies from "../components/listKeyTechnologies";
import clsx from "clsx";
import careerAnimation from "../assets/animations/careerAnimation.json";
import LogoBackground from "../assets/icons/LogoBackground";
import LottieIcon from "../components/lottieIcons";
import { useBreakpointValue } from "../hooks/use-breakpoint-value";

function Career({
  data: {
    strapi: { career, vacancies },
  },
}) {
  const isMobile = useBreakpointValue(["smallMobile", "mobile", "bigMobile"]);

  const [isToggleAboutDescription, setIsToggleAboutDescription] =
    useState(true);

  const [isToggleConditionsDescription, setIsToggleConditionsDescription] =
    useState(false);

  const handleToggleAboutDescription = () => {
    setIsToggleAboutDescription((prev) => !prev);
  };

  const handleToggleConditionsDescription = () => {
    setIsToggleConditionsDescription((prev) => !prev);
  };

  // SETTINGS ANIMATION IMG
  const topAnimation = {
    animationData: careerAnimation,
    loop: 0,
    autoplay: true,
  };

  // END OF SETTINGS ANIMATION IMG

  return (
    <Layout>
      <Seo
        title={career?.data?.attributes?.seo?.metaTitle}
        description={career?.data?.attributes?.seo?.metaDescription}
        image={career?.data?.attributes?.seo?.metaImage?.data?.attributes?.url}
      />
      <div className="main-padding">
        <div className="main-wrapper">
          <SCarrerWrapper>
            <CarrerWrapperBackgroundInner>
              <LogoBackground />
            </CarrerWrapperBackgroundInner>
            <SCarrerTopSectionWrapper>
              <SCarrerTopSection>
                <Typography variant="h1">
                  {career.data.attributes.title}
                </Typography>
                <Typography variant="body1">
                  {career.data.attributes.description}
                </Typography>
              </SCarrerTopSection>
              <SImgTopSection>
                <LottieIcon
                  options={topAnimation}
                  replayInView
                  disableOnHover
                />
              </SImgTopSection>
            </SCarrerTopSectionWrapper>
            <hr className="divider-mobile" />
            {/* TWO SECTIONS. ABOUT, WORKING CONDITIONS */}
            <SCareerDescriptionContainer>
              <SCareerDescriptionContent>
                <SCareerDescriptionCollapse>
                  <Typography
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: career.data.attributes.aboutMalevich.title,
                    }}
                  />
                  <div
                    onClick={handleToggleAboutDescription}
                    className={clsx(isToggleAboutDescription && "rotateSvg")}
                  >
                    <CollapseArrow />
                  </div>
                </SCareerDescriptionCollapse>
                {(!isMobile || isToggleAboutDescription) && (
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: career.data.attributes.aboutMalevich.description,
                    }}
                  />
                )}
              </SCareerDescriptionContent>
              <SCareerDescriptionContent>
                <SCareerDescriptionCollapse>
                  <Typography
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: career.data.attributes.workingConditions.title,
                    }}
                  />
                  <div
                    onClick={handleToggleConditionsDescription}
                    className={clsx({
                      rotateSvg: isToggleConditionsDescription,
                    })}
                  >
                    <CollapseArrow />
                  </div>
                </SCareerDescriptionCollapse>
                {(!isMobile || isToggleConditionsDescription) && (
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html:
                        career.data.attributes.workingConditions.description,
                    }}
                  />
                )}
              </SCareerDescriptionContent>
            </SCareerDescriptionContainer>
            <hr className="divider-mobile" />
            {/* END OF TWO SECTIONS. ABOUT, WORKING CONDITIONS */}
          </SCarrerWrapper>
        </div>
      </div>

      <SJobsWrapper>
        <div className="main-wrapper">
          <Typography variant="h2">Jobs</Typography>
          {/* SEPARATED CARD */}
          {vacancies.data.map((vacancy) => {
            return (
              <SJobsContainer key={vacancy.id}>
                <SJobContent>
                  <SJobNameWrapper>
                    <STitle>Profession</STitle>
                    <SJobName to={`/career/${vacancy.attributes.slug}`}>
                      {vacancy.attributes.profession}
                    </SJobName>
                  </SJobNameWrapper>
                  <SBottomRightSection className='list'>
                    <STitle>List of key technologies</STitle>
                    <ListKeyTechnologies technologiesList={vacancy.attributes.technologiesList} hashtag="hashtagMobile" />
                  </SBottomRightSection>
                </SJobContent>
                <SJobContent>
                  <div>
                    <STitle>
                      {vacancy?.attributes?.shortDescription?.title}
                    </STitle>
                    <SCareerDescriptionUl
                      dangerouslySetInnerHTML={{
                        __html: vacancy.attributes.shortDescription.description,
                      }}
                    />
                  </div>
                  <SBottomRightSection>
                    <SCustomWidth>
                      <STitle>English level</STitle>
                      <SSubtitle>{vacancy.attributes.englishLevel}</SSubtitle>
                    </SCustomWidth>
                    <SCustomWidth>
                      <STitle>Principle</STitle>
                      <SSubtitle>{vacancy.attributes.principle}</SSubtitle>
                    </SCustomWidth>
                  </SBottomRightSection>
                </SJobContent>
              </SJobsContainer>
            );
          })}
          {/* END OF SEPARATED CARD */}
        </div>
      </SJobsWrapper>
      <div className="main-padding">
        <hr className="divider-mobile" />
      </div>
    </Layout>
  );
}

export default Career;

export const query = graphql`
  query {
    strapi {
      career {
        data {
          attributes {
            seo {
              ...seo
            }
            title
            description
            aboutMalevich {
              title
              description
            }
            workingConditions {
              title
              description
            }

            localizations {
              data {
                attributes {
                  title
                  description
                  aboutMalevich {
                    title
                    description
                  }
                  workingConditions {
                    title
                    description
                  }
                }
              }
            }
          }
        }
      }

      vacancies {
        data {
          id
          attributes {
            slug
            profession
            income
            shortDescription {
              title
              description
            }
            englishLevel
            principle
            technologiesList {
              data {
                  id
                attributes {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
