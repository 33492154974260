import React from "react";

const CollapseArrow = ({ width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="#EBF2F7" />
      <path
        d="M16 14.939L11.05 19.889L9.63599 18.475L16 12.111L22.364 18.475L20.95 19.889L16 14.939Z"
        fill="black"
      />
    </svg>
  );
};

export default CollapseArrow;
