import React from "react";

const LogoBackground = ({ width = 1180, height = 1177 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1180 1177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.05">
        <path d="M533.798 423.589L572.313 401.352L572.313 311.297C572.313 294.693 582.078 278.019 594.916 270.606C607.573 263.299 616.072 267.768 616.072 282.517L616.072 376.088L653.412 354.53L653.412 262.913C653.412 247.383 661.639 232.084 675.653 223.993C687.948 216.894 697.17 219.872 697.17 236.574L697.17 329.266L735.595 307.081L735.595 206.185C735.595 173.66 717.693 165.437 691.836 180.366C671.494 192.111 655.672 212.478 649.976 232.957L648.529 233.792C644.099 218.964 629.905 216.122 610.828 227.136C592.113 237.941 577.919 256.881 572.132 277.9L570.505 278.84L570.505 252.37L533.798 273.563L533.798 423.589Z" fill="#151515"/>
        <path d="M806.821 268.792C827.344 256.943 840.634 239.6 847.415 221.718L848.5 221.091L848.5 241.896L885.026 220.807L885.026 119.618C885.026 83.8698 856.998 85.0097 826.078 102.862C792.807 122.071 770.927 151.893 765.593 182.321L801.215 164.881C803.837 153.404 812.064 141.329 825.897 133.342C839.007 125.774 846.511 128.571 846.511 140.878L846.511 141.464C846.511 151.134 837.018 157.884 812.878 174.361C785.393 192.964 760.711 217.177 760.711 250.679C760.711 280.371 780.33 284.086 806.821 268.792ZM817.851 233.708C806.007 240.546 797.508 239.494 797.508 228.066C797.508 216.346 806.459 205.415 820.02 195.534C828.429 189.41 842.171 179.327 846.782 173.344L846.782 189.265C846.782 204.99 834.757 223.946 817.851 233.708Z" fill="#151515"/>
        <path d="M572.313 431.073L533.798 453.31L533.798 653.344L572.313 631.107L572.313 431.073Z" fill="#151515"/>
        <path d="M667.656 578.991C702.012 559.156 725.157 527.723 730.582 496.755L694.96 514.782C691.072 528.454 681.127 540.154 668.289 547.566C649.031 558.684 636.826 551.959 636.826 529.592L636.826 529.494L731.396 474.895L731.396 463.467C731.396 412.482 702.826 403.777 666.119 424.969C625.253 448.563 598.763 495.21 598.763 541.507C598.763 589.074 624.892 603.681 667.656 578.991ZM636.826 503.709C637.64 486.146 649.664 465.529 666.752 455.664C683.478 446.007 695.05 452.219 695.141 470.041L636.826 503.709Z" fill="#151515"/>
        <path d="M777.324 546.05L736.549 569.592L707.798 693.534L706.352 694.369L677.511 603.678L636.826 627.167L685.376 746.875L728.774 721.82L777.324 546.05Z" fill="#151515"/>
        <path d="M798.236 681.716L836.751 659.479L836.751 511.74L798.236 533.977L798.236 681.716Z" fill="#151515"/>
        <path d="M931.552 604.746C968.53 583.397 991.765 551.757 993.573 516.235L957.228 537.218C954.968 554.542 945.204 563.365 932.004 570.986C914.012 581.374 902.349 577.596 902.349 548.88C902.349 520.555 914.102 497.555 932.004 487.22C946.108 479.077 955.149 483.917 957.228 497.661L993.573 476.677C991.946 442.943 967.625 434.226 931.371 455.158C889.239 479.483 863.201 526.064 863.201 572.068C863.201 617.681 888.788 629.436 931.552 604.746Z" fill="#151515"/>
        <path d="M1057.95 447.323C1058.04 427.931 1068.98 410.285 1084.71 401.203C1100.35 392.172 1109.57 397.69 1109.48 416.202L1109.48 502.016L1148 479.78L1148 386.542C1148.09 351.328 1128.92 341.101 1099.99 357.805C1078.93 369.967 1064.82 388.854 1058.49 410.187L1056.87 411.126L1056.87 334.648L1019.44 356.259L1019.44 554.006L1057.95 531.77L1057.95 447.323Z" fill="#151515"/>
        <path d="M798.236 507.522L836.751 485.285L836.751 418.288L798.236 440.524L798.236 507.522Z" fill="#151515"/>
        <path d="M3.90731e-10 579.816L487.396 298.4L487.396 861.18L1.23987e-05 1142.6L3.90731e-10 579.816Z" fill="#151515"/>
        <path d="M46.4175 1062.18L85.8556 1039.41L85.8555 948.927C85.8555 932.243 95.8539 915.38 109 907.79C121.961 900.307 130.663 904.705 130.663 919.524L130.663 1013.54L168.898 991.468L168.898 899.412C168.898 883.807 177.322 868.344 191.672 860.06C204.262 852.791 213.705 855.681 213.705 872.463L213.705 965.598L253.051 942.882L253.051 841.503C253.051 808.822 234.72 800.758 208.243 816.045C187.413 828.071 171.212 848.711 165.38 869.351L163.898 870.206C159.362 855.356 144.827 852.658 125.294 863.936C106.13 875 91.5954 894.187 85.6704 915.371L84.004 916.333L84.004 889.737L46.4175 911.438L46.4175 1062.18Z" fill="white"/>
        <path d="M313.943 820.576C314.036 801.091 325.238 783.239 341.346 773.939C357.362 764.692 366.805 770.134 366.713 788.736L366.713 877.259L406.151 854.489L406.151 758.507C406.244 723.123 386.617 713.06 356.992 730.164C335.422 742.618 320.979 761.751 314.499 783.256L312.832 784.218L312.832 707.374L274.505 729.503L274.505 930.495L313.943 907.725L313.943 820.576Z" fill="white"/>
        <path d="M382.943 398.908L452.569 358.706L452.569 439.1L382.943 479.302L382.943 398.908Z" fill="white"/>
      </g>
    </svg>
  );
};

export default LogoBackground;
