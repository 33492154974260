import React from "react";
import {
  SKeyTechnologiesContainer,
  SKeyTechnologiesContent,
  SKeyTechnologiesHashtag,
} from "../../styles/career";

const ListKeyTechnologies = ({ technologiesList, hashtag }) => {
    return (
        <SKeyTechnologiesContainer>
            <SKeyTechnologiesContent>
                {technologiesList.data.map((item) => {
                    return (
                        <SKeyTechnologiesHashtag key={item.id} className={hashtag}>
                            {item.attributes.title}
                        </SKeyTechnologiesHashtag>
                    );
                })}
            </SKeyTechnologiesContent>
        </SKeyTechnologiesContainer>
    );
};

export default ListKeyTechnologies;
